import React, { Component } from "react";
import { Icon } from "@iconify/react";
import angularIcon from "@iconify/icons-logos/express";
import reactIcon from "@iconify/icons-logos/react";
import vueIcon from "@iconify/icons-logos/vue";
import Image from "../myProfile.jpg";

class About extends Component {
  render() {
    let profilepic = ''
    if (this.props.sharedBasicInfo) {
       profilepic = "images/" + this.props.sharedBasicInfo.image;
    }
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.about;
      var hello = this.props.resumeBasicInfo.description_header;
      var about = this.props.resumeBasicInfo.description;
      var linkedIn = this.props.resumeBasicInfo.linkedIn;
      var git = this.props.resumeBasicInfo.git;
      var email = this.props.resumeBasicInfo.email;
      var phone = this.props.resumeBasicInfo.phone;
    }

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="200px"
                    width={"200px"}
                    style={{borderRadius:125}}
                    src={Image}
                    alt="Avatar placeholder"
                  />
                  
                  {/* <Icon
                    icon={reactIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={angularIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  /> */}
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <br />
                    <span className="wave">{hello} :) </span>
                    <br />
                    <br />
                    {/* {about} */}
                    {"I am a software engineer with 5+ years of experience. I am a developer with extensive experience in developing dashboards for mid-level organizations using the MERN stack. I have worked with a wide array of industries, e.g. customer-facing e-commerce websites, e-commerce mobile apps using react native, and hour healthcare platforms."}
                    <br />
                    <br />
                    {"Front-end:"}
                    <br />
                    {"React, React-native, Highcharts, apexcharts, MUI, antD, Bootstrap."}
                    <br />
                    <br />
                    {"Back-end:"}
                    <br/>
                    {"ExpressJs, Nodejs, Mongodb, MySQL, Postgresql, aggregation pipelines, data streams, and materialized views."}
                    <br/>
                    <br />
                    {"Other Skills:"}
                    <br/>
                    {"Git, Bitbucket, Github, Gitlab, Jira, ClickUp, Trello, Agile, Scrum, REST APIs, Zapier app development, Zapier Integration, Stripe connected account expert, Payfort integration expert, ETL Pipelines, Aggregation Pipelines."}
                    <br/>
                    <br/>
                    email: <a href="mailto:abubakarwaris@gmail.com" className="personal-info" style={{textDecoration:'none'}}>abubakarwaris@gmail.com</a>
                    <br />
                    phone: {+923075861606}
                    <br />
                    LinkedIn: <a href="https://www.linkedin.com/in/muhammad-abu-bakar-waris/" className="personal-info">Muhammad Abu Bakar Waris</a>
                    <br />
                    Git: <a href="https://github.com/abubakarwaris1" className="personal-info">abubakarwaris1</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
